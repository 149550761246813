<template>
	<ul class="list-group list-group-flush mb-2" v-if="task.subtasks.length">
		<li class="list-group-item">
			<div class="progress progress-sm" :title="`${percent}%`">
				<div class="progress-bar" role="progressbar" :aria-valuenow="percent" aria-valuemin="0" aria-valuemax="100" :style="{ width: `${percent < 1 ? 1 : percent}%`}"></div>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'TaskProgress',
	props: ['task'],
	computed: {
		percent() {
			return parseInt( 
				this.task.subtasks.reduce((acc, item) => {
					return acc += (item.is_finished ? 1 : 0)
				}, 0) * 100 / this.task.subtasks_aggregate.aggregate.count
			)
		}
	}
}
</script>

<style scoped>
.list-group-item {
	padding: 0px;
}
.list-group-item p {
    padding: 0px 1rem;
}
.progress {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
</style>