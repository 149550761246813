<template>
    <div class="card border-primary border mb-3">
        <div class="card-header">
            <p-parts-widget :project="project" :project_description="rf.project_description" />

            <h4 class="mt-0 mb-0 card-title">
                <text-editable :styles="{'min-width': '300px', 'max-width': '100%'}" :editable="project.title" @update:editable="editItem({key: 'title', value: $event})">
                    <p-parts-color-badge :project="project" />
                    <span>{{ project.title }}</span>
                </text-editable>
            </h4>
        </div>
        <div class="card-body pb-3">
            <div v-if="project.is_finished" class="badge badge-success mb-3">Завершен</div>

            <div class="mb-2">
                <p-parts-category :project="project" />
            </div>

            <div class="mb-2">
                <p-parts-color :project="project" />
            </div>

            <div class="mb-3">
                <p-parts-notification :project="project" />
            </div>

            <div class="mb-3 description">
                <textarea-editable :styles="{ width: '100%' }" :object="{ project_id: project.id }" ref="project_description"  :editable="project.description" @update:editable="editItem({key: 'description', value: $event})">
                    <div v-html="project.description && project.description != '<p></p>' ? project.description : 'Дважды нажмите здесь, чтобы создать описание'"></div>
                </textarea-editable>
            </div>

            <p-parts-stats :project="project" />

            <p-parts-members :project="project" />
        </div>

        <project-progress :project="project" />
    </div>
</template>

<script>
import TextEditable from '@/components/editable/TextEditable.vue'
import TextareaEditable from '@/components/editable/TextareaEditable.vue'

import PPartsMembers from '@/components/project/_parts/Members.vue'
import PPartsWidget from '@/components/project/_parts/Widget.vue'
import PPartsStats from '@/components/project/_parts/Stats.vue'
import PPartsCategory from '@/components/project/_parts/CategoryForm.vue'
import PPartsColor from '@/components/project/_parts/Color.vue'
import PPartsColorBadge from '@/components/project/_parts/ColorBadge.vue'
import PPartsNotification from '@/components/project/_parts/Notification.vue'

import ProjectProgress from '@/components/progress/ProjectProgress.vue'

export default {
    name: 'ProjectForm',
    props: ['project'],
    data: () => {
        return {
            rf: {
                project_description: null
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.rf.project_description = this.$refs.project_description
        });
    },
    methods: {
        async editItem(options = {}) {
            let {key, value} = options

			if(key) {
                this.$set(this.project, key, value)
                
                await this.$orm.mutate({
                    project: {
                        update: {
                            where: { id: { _eq: this.project.id }},
                            _set: { [key]: value }
                        }
                    }
                })
            }
        }
    },
    components: {
        TextEditable,
        TextareaEditable,

        PPartsMembers,
        PPartsWidget,
        PPartsStats,
        PPartsCategory,
        PPartsColor,
        PPartsColorBadge,
        PPartsNotification,

        ProjectProgress
    }
}
</script>

<style scoped>
.card-title {
    line-height: 26px;
}
.card-header {
    border-bottom: 1px solid var(--primary);
}
.control-row-wrapper {
    display: flex;
    font-size: 20px;
    line-height: 28px;
}
.control-row-wrapper i {
    display: inline-block;
}
</style>