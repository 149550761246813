<template>
    <div class="control-row-wrapper">
        <i class="mdi mdi-flag-variant mr-2"></i>

        <span class="text-muted category-title" v-if="!category" @click="$refs.category_form.openModal()">Категория не выбрана</span>
        <b class="category-title" v-else>{{ category.category.title }}</b>
        
        <a class="ml-2" @click="$refs.category_form.openModal()" title="Изменить категорию">
            <i v-if="!loading" class="mdi mdi-pencil"></i>
            <i v-else class="spinner-border spinner-border-sm"></i>
        </a>

        <a class="ml-1" v-if="category" @click="deleteCategory()" title="Удалить категорию">
            <i v-if="!loading" class="mdi mdi-delete"></i>
            <i v-else class="spinner-border spinner-border-sm"></i>
        </a>

        <category-modal-form ref="category_form" :show_use="true" @use-item="setCategory($event)" />
    </div>
</template>

<script>
import CategoryModalForm from '@/components/modal/Category.vue'

export default {
    name: 'ProjectCategory',
    props: ['project'],
    data: () => {
        return {
            loading: false
        }
    },
    computed: {
        category() {
            return this.project.users_categories.length ? this.project.users_categories[0] : null
        }
    },
    beforeMount() {

    },
    methods: {
        async setCategory(id) {
            this.loading = true

            if(this.category) {
                var [err] = await this.$orm.mutate({
                    project2category: {
                        update: {
                            where: { id: { _eq: this.category.id } },
                            _set: { category_id: id }
                        }
                    }
                })
                if(err) console.log(err)
            } else {
                var [err] = await this.$orm.mutate({
                    project2category: {
                        insert: {
                            objects: {
                                project_id: this.project.id,
                                category_id: id
                            }
                        }
                    }
                })
                if(err) console.log(err)
            }

            setTimeout(() => { this.loading = false }, 450);
        },
        async deleteCategory() {
            if(!confirm('Уверены, что хотите удалить?')) return;
            this.loading = true

            var [err] = await this.$orm.mutate({
                project2category: {
                    delete: {
                        where: { id: { _eq: this.category.id } }
                    }
                }
            })
            if(err) console.log(err)

            setTimeout(() => { this.loading = false }, 450);
        }
    },
    components: {
        CategoryModalForm
    }
}
</script>

<style scoped>
.category-title {
    font-size: 14px;
}
</style>