<template>
    <div class="control-row-wrapper">
        <i class="mdi mdi-format-color-fill mr-2"></i>
        <input class="form-control color-selector" id="example-color" type="color" v-model="color">
        <a class="ml-1" v-if="colorInstance" @click="deleteItem()" title="Удалить цвет">
            <i v-if="!loading" class="mdi mdi-delete"></i>
            <i v-else class="spinner-border spinner-border-sm"></i>
        </a>
    </div>
</template>

<script>
export default {
    name: 'ProjectColorForm',
    props: ['project'],
    data: () => {
        return {
            color: null,
            loading: false
        }
    },
    computed: {
        colorInstance() {
            return this.project.color.length ? this.project.color[0] : null
        }
    },
    mounted() {
        this.initiate()
    },
    methods: {
        initiate() {
            this.color = this.colorInstance ? this.colorInstance.color : null
        },
        async deleteItem() {
            if(!confirm('Уверены, что хотите удалить?')) return;
            this.loading = true

            var [err] = await this.$orm.mutate({
                project_color: {
                    delete: {
                        where: { id: { _eq: this.colorInstance.id } }
                    }
                }
            })
            if(err) console.log(err)
            else {
                this.project.color = []
                this.color = null
            }

            setTimeout(() => { this.loading = false }, 450);
        }
    },
    watch: {
        project: function() {
            this.initiate()
        },
        color: async function(val) {
            this.loading = true

            if(this.colorInstance) {
                var [err] = await this.$orm.mutate({
                    project_color: {
                        update: {
                            where: { id: { _eq: this.colorInstance.id } },
                            _set: { color: val }
                        }
                    }
                })
                if(!err) {
                    this.project.color[0].color = val
                }
            } else {
                var [err] = await this.$orm.mutate({
                    project_color: {
                        insert: {
                            objects: {
                                project_id: this.project.id,
                                color: val 
                            }
                        }
                    }
                })
                if(!err) {
                    this.project.color = [{ color: val }]
                }
            }

            setTimeout(() => { this.loading = false }, 450);
        }
    }
}
</script>

<style>
.color-selector {
    cursor: pointer;
    display: inline-block;
    width: 50px;
    height: 28px;
    min-height: 16px !important;
    padding: 2px 4px;
}
</style>