<template>
    <div class="card bg-light d-block mb-1">
        <div class="card-body pb-0">
            <t-finished-parts-widget :task="task" :timers="timers" />

            <h5 class="mt-0 pb-2">
                <router-link :to="{ name: 'TaskView', params: { id: task.project_id, task_id: task.id } }">
                    <span class="text-title">{{ task.title }}</span>
                </router-link>
            </h5>

            <div class="mb-1 description" v-if="task.description" v-html="task.description"></div>

            <t-parts-stats class="mb-1" :task="task" />
        </div>

        <task-progress :task="task" />
    </div>
</template>

<script>
import TaskProgress from '@/components/progress/TaskProgressShort.vue'

import TFinishedPartsWidget from '@/components/task/_parts/FinishedWidget.vue'
import TPartsStats from '@/components/task/_parts/Stats.vue'

export default {
    name: 'TaskCard',
    props: ['task', 'timers'],
    components: {
        TaskProgress,
        TFinishedPartsWidget,
        TPartsStats
    }
}
</script>

<style scoped>
h4 {
    line-height: 24px;
}
.card-body {
    padding: 1rem;
}
</style>