<template>
    <div ref="category_modal" :id="hashtag" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-body pt-1">
                    <h5>Добавить категорию</h5>
                    <div class="input-group">
                        <input type="text" class="form-control form-control-sm" ref="input" v-model="form.title" @keyup.enter="submitForm()">
                        <div class="input-group-append">
                            <button v-if="!loading" class="btn btn-primary btn-sm" type="submit" @click.prevent="submitForm()">
                                Добавить
                            </button>
                            <button v-else class="btn btn-primary btn-sm" type="button" disabled>
                                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                Загрузка...
                            </button>
                        </div>
                    </div>

                    <hr>

                    <table class="table table-borderless table-sm">
                        <thead>
                            <tr>
                                <th>Категории</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="!categories.length">
                                <td colspan="2">Пока нет добавленных категорий</td>
                            </tr>
                            <tr v-else v-for="(category, i) in categories" :key="category.id">
                                <td class="title">
                                    <text-editable :styles="{'min-width': '300px', 'max-width': '100%'}" :editable="category.title" @update:editable="editItem({key: 'title', value: $event, id: category.id, i})">
                                        <span class="">{{ category.title }}</span>
                                    </text-editable>
                                </td>
                                <td class="text-right">
                                    <div class="btn-group">
                                        <button v-if="show_use" class="btn btn-sm btn-primary" @click="$emit('use-item', category.id); closeModal();">использовать</button>
                                        <button class="btn btn-sm btn-danger"><i class="mdi mdi-delete"></i></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-light" data-dismiss="modal">Закрыть</button>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
	<!-- /.modal -->
</template>

<script>
import TextEditable from '@/components/editable/TextEditable.vue'

export default {
    name: 'CategoryModal',
    props: ['show_use'],
    data: () => {
        return {
            hashtag: 'category-modal',
            _form: {
				title: '',
			},
            form: {},
            loading: false,
            categories: []
        }
    },
    beforeMount() {
        this.resetForm();
        this.data()
    },
    methods: {
        openModal() {
            $(this.$refs.category_modal).modal('show')
        },
        closeModal() {
            $(this.$refs.category_modal).modal('hide')
		},
		resetForm() {
			this.form = Object.assign({}, this._form);
		},
        async data() {
            var [err, response] = await this.$orm.query({
                project_category: {}
            })

            this.categories = response
        },
        async submitForm() {
			if (this.loading) return $.NotificationApp.send('Ошибка', 'Пожалуйста, подождите', 'top-right', '', 'error');

			if (!this.form.title)
				return $.NotificationApp.send('Ошибка', 'Пожалуйста, введите название', 'top-right', '', 'error');

            this.loading = true;

            var [err, response] = await this.$orm.mutate({
                project_category: {
                    insert: {
                        objects: {
                            title: this.form.title
                        }
                    }
                }
            })

			if (err) {
				this.loading = false;
				log.error('code-modal', 'methods.submitForm', '$http.request', err);
				return $.NotificationApp.send('Ошибка', err.message, 'top-right', '', 'error');
			}
			if (response.error) {
				this.loading = false;
				log.error('code-modal', 'methods.submitForm', '$http.response', response);
				return $.NotificationApp.send('Ошибка', response, 'top-right', '', 'error');
			}
			this.loading = false;

            this.data()
			this.resetForm();
        },
        async editItem(options = {}) {
            let {key, value, id, i} = options

			if(key) {
                this.$set(this.categories[i], key, value)
                
                await this.$orm.mutate({
                    project_category: {
                        update: {
                            where: { id: { _eq: id }},
                            _set: { [key]: value }
                        }
                    }
                })
            }
        }
    },
    components: {
        TextEditable
    }
}
</script>

<style scoped>
td.title {
    line-height: 30px;
}
</style>