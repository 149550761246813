<template>
	<div>
		<div class="row">
			<div class="col-sm-6">
				<div class="text-sm-left">
					<div class="btn-group mb-3">
						<button type="button" class="btn" :class="filter.finished === false ? 'btn-primary' : 'btn-light'" @click="filter.finished = false">Активные</button>
						<button type="button" class="btn" :class="filter.finished === true ? 'btn-primary' : 'btn-light'" @click="filter.finished = true">Завершенные</button>
					</div>
				</div>
			</div><!-- end col-->
			<div class="col-sm-6">
				<div class="text-sm-right">
					<div class="btn-group mb-3">
						<router-link :to="{ name: 'ProjectList' }">
							<i class="dripicons-backspace"></i> назад в список проектов 
						</router-link>
					</div>
				</div>
			</div><!-- end col-->
		</div>

	<!-- LOADING -->
		<div class="row" v-if="item === null">
			<div class="col-md-12 text-center">
				<div class="spinner-grow" role="status">
					<span class="sr-only">Загрузка...</span>
				</div>
			</div>
		</div>

	<!-- LOADED -->
		<div class="row" v-else>

		<!-- LEFT COLUMN -->
			<div class="col-md-12 col-lg-12 col-xl-6">

				<task-create :project="item" />

				<transition-group  name="flip-list" tag="div" v-if="this.filter.finished === false">
					<task-card v-for="task in fActiveTasks" :key="task.id" :task="task" :timers="item.timers" />

					<task-card v-for="task in fInactiveTasks" :key="task.id" :task="task" :timers="item.timers" />
				</transition-group>
				
				<div v-else>
					<task-finished-card v-for="task in filteredTasks" :key="task.id" :task="task" :timers="item.timers" />
				</div>
			</div>

		<!-- RIGHT COLUMN -->
			<div class="col-md-12 col-lg-12 col-xl-6">

				<project-form :project="item" />

				<timer-chart title="проект" :timers="item.timers" :users="[item.owner, ...item.guests.map(g => g.user)]" />
			</div>
		</div>

	</div>
</template>

<script>
import { mapState } from 'vuex'
import log from '@/plugins/log'
import { to } from '@/plugins/utils'
import { bus } from '@/plugins/bus'

import ProjectForm from '@/components/project/Form'
import TaskCard from '@/components/task/Card'
import TaskFinishedCard from '@/components/task/FinishedCard'
import TaskCreate from '@/components/task/Create'

import TimerChart from '@/components/timer/TimerChart.vue'

export default {
	name: 'ProjectList',
	data: () => {
		return {
			filter: {
				finished: false
			},
			item: null,
			unsubsribe: null,
			timers: null
		}
	},
	computed: {
		...mapState([
			'current_timer'
		]),
		filteredTasks() {
			return this.item.tasks.filter(item => {
				return item.is_finished === this.filter.finished
			})
		},
		fActiveTasks() {
			return this.filteredTasks.filter(item => {
				if(item.subtasks_aggregate.aggregate.count == 0) return true
				return !!item.subtasks.reduce((acc, i) => {
					return acc += i.is_finished ? 0 : 1
				}, 0) 
			})
		},
		fInactiveTasks() {
			return this.filteredTasks.filter(item => {
				if(item.subtasks_aggregate.aggregate.count == 0) return false
				return !item.subtasks.reduce((acc, i) => {
					return acc += i.is_finished ? 0 : 1
				}, 0) 
			})
		},
		id() {
			return this.$route.params.id
		}
	},
	async beforeMount() {
		await this.data()
	},
	beforeRouteLeave(to, from , next) {
		if(typeof this.unsubsribe == 'function') {
			this.unsubsribe()
			this.$store.commit('navigation/set_meta', {})
		}
		next()
	},
	methods: {
		async data() {
			this.unsubsribe = await this.$orm.queryAndSubscribe({
				project: {
					where: { id: { _eq: this.id } },
					fragment: 'w_tasks'
				}
			}, ([err, response]) => {
				if(err) {
					log.error('/views/project/view', 'methods.data', '$orm.queryAndSubscribe', err)
					return $.NotificationApp.send("Ошибка", "Не получается отправить запрос", "top-right", "", "error")
				}
				log.debug('/views/project/view', 'methods.data', '$orm.queryAndSubscribe', response)
				this.item = response

				this.$store.commit('navigation/set_meta', {
					link: { name: 'ProjectView', params: { id: response.id } },
					title: `<span class="text-muted">Проект:</span> ${response.title}`
				})

				this.$route.meta.title = this.item.title
			}, { getFirst: true })
		},
	},
	watch: {
		'$route.params': async function(to, from) {
			await this.data()
		}
	},

	components: {
		ProjectForm,
		TaskCard,
		TaskFinishedCard,
		TaskCreate,
		TimerChart
	}
}
</script>

<style scoped>
.description {
	white-space: pre-line;
}
.flip-list-move {
  transition: transform 0.5s;
}
</style>