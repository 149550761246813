<template>
    <div class="row">
        <div class="col-6">
            <div v-if="task.finished_at" class="finish-date">{{ format_date(task.finished_at) }}</div>
        </div>

        <div class="col-6 text-right">
            <disabled-timer-badge :object="{ project_id: task.project_id, task_id: task.id }" :timers="timers"/>

            <a href="#" title="Удалить задачу" v-if="!task.is_deleted"  @click.prevent="deleteItem()">
                <i v-if="!loading.delete" class="mdi mdi-delete"></i>
                <i v-else class="spinner-border spinner-border-sm"></i>
            </a>
        </div>
    </div>
</template>

<script>
import dayjs from '@/plugins/day'
import DisabledTimerBadge from '@/components/timer/DisabledTimerBadge.vue' 

export default {
    name: 'TaskCardWidget',
    props: ['task', 'timers', 'task_description'],
    data: () => {
        return {
            loading: {
                delete: false
            }
        }
    },
    methods: {
        format_date(date) {
			return dayjs(date).format('DD.MM HH:mm');
		},
        async deleteItem() {
            if(!confirm(`Удалить ${this.task.title} ?`))
                return false

            this.loading.delete = true
            var [err, response] = await this.$orm.mutate({
                task: {
                    update: {
                        where: { id: { _eq: this.task.id }},
                        _set: { is_deleted: true }
                    }
                },
                project: {
                    update: {
                        where: { id: { _eq: this.task.project_id } },
                        _set: { updated_at_trigger: true },
                        fields: ['id']
                    }
                }
            })
            if(!err && !response.error)
                this.project.is_deleted = true

            this.loading.delete = false
        }
    },
    components: {
        DisabledTimerBadge
    }
}
</script>

<style scoped>
div {
    font-size: 16px !important;
    color: #6c757d !important;
}
div a {
    color: #6c757d !important;
}
.finish-date {
    line-height: 24px;
    font-size: 12px !important;
}
</style>