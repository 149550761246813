<template>
    <div class="control-row-wrapper">
        <router-link :to="{ name: 'SettingsNotification' }">
            <i class="mdi mdi-bell mr-2" title="Подписка на уведомления по задачам"></i>
        </router-link>
        <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" class="custom-control-input" id="proj-task-noti-created" v-model="form.created">
            <label class="custom-control-label" for="proj-task-noti-created">Новые</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" class="custom-control-input" id="proj-task-noti-finished" v-model="form.finished">
            <label class="custom-control-label" for="proj-task-noti-finished">Выполненные</label>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { sleep, mergeDeep } from '@/plugins/utils'

export default {
    name: 'ProjectNotification',
    props: ['project'],
    data: () => {
        return {
            watching: false,
            form: {
                created: false,
                finished: false
            }
        }
    },
    computed: {
		...mapState({
			user: state => state.auth.user,
			settings: state => state.settings,
		})
	},
    mounted() {
        this.initiate()
    },
    methods: {
        initiate() {
            this.watching = false;
            this.form = {
                created: false,
                finished: false
            }
            
            if(this.user.notification_settings) {
                if(this.user.notification_settings['task:new']) {
                    if(this.user.notification_settings['task:new'].settings[this.project.id])
                        this.form.created = true
                }
                if(this.user.notification_settings['task:finished']) {
                    if(this.user.notification_settings['task:finished'].settings[this.project.id])
                        this.form.finished = true
                }
            }
            this.watching = true
        }
    },
    watch: {
        project: function() {
            this.initiate()
        },
        form: {
            deep: true,
            async handler() {
                if(!this.watching) return;

                this.$store.commit('auth/create_notification_object', { key: 'task:new' })
                this.$store.commit('auth/create_notification_object', { key: 'task:finished' })

                let notification_settings = this.user.notification_settings

                if(this.form.created === true) {
                    notification_settings['task:new'].enabled = true
                    notification_settings['task:new'].settings[this.project.id] = true
                } else {
                    delete notification_settings['task:new'].settings[this.project.id]
                }

                if(this.form.finished === true) {
                    notification_settings['task:finished'].enabled = true
                    notification_settings['task:finished'].settings[this.project.id] = true
                } else {
                    delete notification_settings['task:finished'].settings[this.project.id]
                }

                this.$store.commit('auth/set_user_notification_settings', notification_settings)
                await this.$store.dispatch('auth/update_notification_settings')
            }
        }
    }
}
</script>

<style scoped>
label::before, label::after {
    top: 6px;
}
label {
    font-size: 14px;
}
</style>